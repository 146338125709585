import React from "react";
import { navigate, Link } from "gatsby";
import { handleLogin, isLoggedIn } from "../components/auth/auth";
import logo from "../images/logo.png";

class Login extends React.Component {
    state = {
        password: ``,
        wrongPassword: false
    };

    handleUpdate = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const res = handleLogin(this.state);
        if(!res){
          this.setState({wrongPassword: true});
        } else {
          navigate("/");
        }
    };

    render() {
        if (isLoggedIn()) {
            navigate("/");
        }

        return (
            <div>
                <div className="login">
                    <Link to="/" className="logo">
                        <img src={logo} alt="logo"></img>
                    </Link>
                    <form
                        method="post"
                        onSubmit={(event) => {
                            this.handleSubmit(event);
                        }}
                    >
                        <p className="description">
                          Dette indhold er beskyttet med en adgangskode. For at se det, indtast venligst din adgangskode nedenfor:
                        </p>
                        <label className="password-label">Adgangskode:</label>
                        <input className="password-input" type="password" name="password" onChange={this.handleUpdate} />
                        <input className="password-submit" type="submit" value="Fortsæt" />
                        {this.state.wrongPassword 
                          ?  <p className="login-error">Forkert adgangskode, prøv venligst igen</p>
                          : null
                        }
                    </form>
                </div>
            </div>
        );
    }
}

export default Login;
